@import 'global';
@import 'font';

@import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';

@function get-vw($size) {
  @return get-mobile-vw($size);
}

$content-x-padding : get-vw(20px);
$content-y-padding : 0;

$base-font-size : get-vw(12px);

html{
  font-size : $base-font-size;
}

body{

  .react-datepicker-popper {
    z-index: 999;
  }

  .d-mobile-none {
    display: none;
  }

  .show-more-less-clickable {
    font-weight: 500;
    
    &:hover {
      cursor: pointer;
    }
  }

  .capitalize {
    text-transform: capitalize;
  }

  .page_wrapper{
    background-color : $background-color;
    @extend .ff-poppins;

    min-height: 100vh;
    display: flex;
    flex-direction: column;

    /*background-color : $background2-color;*/
    padding-bottom: get-vw(50px);

    --profil-color : #{$profil-recruteur-color};

    &[data-profil="0"]{
      --profil-color : #{$profil-recruteur-color};
      --profil-color2 : #{$profil-recruteur-color2};
      --profil-color3 : #{$profil-recruteur-color3};
      --profil-color4 : #{$profil-recruteur-color4};
    }
    &[data-profil="1"]{
      --profil-color : #{$profil-candidat-color};
      --profil-color2 : #{$profil-candidat-color2};
      --profil-color3 : #{$profil-candidat-color3};
      --profil-color4 : #{$profil-candidat-color4};
    }

  }

  .content_wrapper{
    position: relative;
    flex-grow: 1;
    padding: $content-y-padding $content-x-padding;

    font-size : 1rem;

    &_vertically_centered{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

  }

  //autre
  .react-datepicker-wrapper{
    width: 100%;
  }

  //global
  a{
    color : inherit;
  }

  //title
  h1{
    @extend .ff-inter;
    @extend .fw-semibold;
    color : $main-color;
    font-size : 2rem;
    margin-bottom : get-vw(20px);

    &+p{
      margin-top: get-vw(-10px);
      margin-bottom: get-vw(30px);

      color : $generic_bloc_secondary_color;
      font-size : 1.2rem
    }

  }

  h2{
    color : $main-color;
    font-size: 1.33rem;
    @extend .fw-medium;
    line-height: 2rem;
    margin-bottom : get-vw(11px);
  }

  h3{
    color : $main-color;
    font-size: 1.16rem;
    @extend .fw-semibold;
    margin-bottom : get-vw(8px);
    line-height: 1.75rem;
  }
  
  h4{
    color : $main-color;
    font-size: get-vw(12px);
    @extend .fw-medium;
    margin-bottom : get-vw(15px);
  }

  h5{
    color: $main-color;
    font-size: 1rem;
    margin-bottom : get-vw(15px);
  }

  .text_disabled {
    font-style: italic;
    color: $grey-color;
  }

  //cta
  .cta{
    display: inline-block;
    border: 0;
    background-color: transparent;
    outline: 0;
    cursor: pointer;
    text-decoration: none;

    &.small{
      @extend .ff-inter;
      @extend .fw-semibold;

      border-radius: get-vw(5px);
      text-align: center;
      font-size: get-vw(10px);
      line-height: normal;

      padding: get-vw(7px) get-vw(13px);
    }

    &.full{
      @extend .ff-inter;
      @extend .fw-semibold;

      border-radius: get-vw(6px);
      text-align: center;
      font-size: 1.3rem;
      //line-height: get-vw(56px);
      line-height: get-vw(36px);

      padding: get-vw(10px) get-vw(10px);
      width: 100%;

      margin-bottom: get-vw(15px);
    }

    &.blue{
      background: $cta-blue-bg;
      color: $cta-blue-color;

      &.reverse{
        background: $cta-blue-color;
        color: $cta-blue-bg;
        border : get-vw(1px) solid $cta-blue-bg;
      }
    }

    &.transparent{
      background: transparent;
      color: $cta-blue-bg;
    }

    &.dot_more{
      background-image : url("../images/mobile/cta_dot_more.svg");
      background-repeat: no-repeat;
      background-size: get-vw(2.2px) auto;
      background-position: center;

      width: get-vw(10px);
      height: get-vw(10px);
    }

    &.return{
      background-image : url("../images/mobile/arrow.svg");
      background-repeat: no-repeat;
      background-size: get-vw(24px) auto;
      background-position: center;

      width: get-vw(42px);
      height: get-vw(42px);

      border: get-vw(1px) solid $grey-color;
      border-radius: 100%;
    }

    &.bookmark{
      background-image : url("../images/mobile/match_like.svg");
      background-repeat: no-repeat;
      background-size: get-vw(24px) auto;
      background-position: center;

      width: get-vw(42px);
      height: get-vw(42px);

      border: get-vw(1px) solid $grey-color;
      border-radius: 100%;

      &.active{
        background-image : url("../images/mobile/match_like_active.svg");
      }
    }

    &.like{
      background-image : url("../images/mobile/question_like.svg");
      background-repeat: no-repeat;
      background-size: get-vw(24px) auto;
      background-position: center;

      width: get-vw(42px);
      height: get-vw(42px);

      border: get-vw(1px) solid $grey-color;
      border-radius: 100%;

      &.active{
        background-image : url("../images/mobile/question_like_active.svg");
        border: get-vw(1px) solid $cta-color;
      }
    }

    &.comment{
      background-image : url("../images/mobile/question_answer_count.svg");
      background-repeat: no-repeat;
      background-size: get-vw(24px) auto;
      background-position: center;

      width: get-vw(42px);
      height: get-vw(42px);

      border: get-vw(1px) solid $grey-color;
      border-radius: 100%;
    }

    &.message{
      background-image : url("../images/mobile/message.svg");
      background-repeat: no-repeat;
      background-size: get-vw(24px) auto;
      background-position: center;

      width: get-vw(42px);
      height: get-vw(42px);

      border: get-vw(1px) solid $grey-color;
      border-radius: 100%;
    }

    &.disabled{
      cursor: default;
    }

    &.social_login{
      border : get-vw(1px) solid $form-input-border;
      border-radius: get-vw(8px);

      margin-bottom: get-vw(15px);

      background-color : $white-color;
      height: get-vw(56px);
      /*max-width: get-vw(150px);*/
      width: 100%;

      font-size : get-vw(16px);
      color : $black-color;
      @extend .fw-semibold;
      @extend .ff-inter;

      span{
        display: inline-block;
        background-size : get-vw(19px) auto;
        background-position: left center;
        background-repeat: no-repeat;

        padding-left: get-vw(25px);
      }

      &.google span{
        background-image : url("../images/mobile/cta_connect_google.svg");
      }
      &.apple span {
        background-image: url("../images/mobile/cta_connect_apple.svg");
      }
      &.linkedin span{
        background-image : url("../images/mobile/cta_connect_linkedin.svg");
      }
    }
  }

  //picto
  .picto{

    &:is(span){
      display: inline-block;
      width: get-vw(15px);
      height: get-vw(15px);

      background-size : 100% auto;
      background-repeat: no-repeat;
    }

    &:is(div){
      background-size : get-vw(15px) auto;
      background-position: left center;
      background-repeat: no-repeat;

      padding-left: get-vw(20px);
    }

    &.user{background-image : url("../images/mobile/user-circle.png");}
    &.bar{background-image : url("../images/mobile/picto_bar.svg");}
    &.location{background-image : url("../images/mobile/picto_location.svg");}
    &.language{background-image : url("../images/mobile/picto_language.svg");}
    &.experience{background-image : url("../images/mobile/picto_experience.svg");}
  }

  //form
  form,
  div.form{

    .form_password_strength{
      > div{
        border-radius: get-vw(2px);
        overflow: hidden;

        //spacer
        > div:nth-of-type(even){
          display: none;
        }

        //jauge
        > div:nth-of-type(odd){
          height: get-vw(4px) !important;
        }
      }
    }

    .form_row_wrapper{
      margin-bottom : get-vw(15px);

      &.type1{
        input[type="text"],
        input[type="email"],
        input[type="password"],
        select{
          background-color : $white-color;
          height: get-vw(56px);
          border: get-vw(1px) solid $form-input-border;
          border-radius: get-vw(8px);
          padding: 0 get-vw(15px);
          width: 100%;
        }

        input[type="submit"]{
          display: inline-block;
          height: get-vw(56px);
          line-height: get-vw(56px);

          background-color : $cta-color;
          border-radius: get-vw(8px);
          border: 0;
          outline: 0;

          text-align: center;
          font-size : get-vw(16px);
          @extend .fw-medium;
          color : $white-color;
          width: 100%;

          cursor: pointer;
        }
      }

      &.type2{
        position: relative;
        margin-bottom : get-vw(7px);

        label{
          position: absolute;

          color: $form-style2-label;
          font-size: get-vw(10px);
          @extend .fw-medium;
          line-height: get-vw(10px);

          top: get-vw(3px);
          left : get-vw(11px);
        }

        input[type="text"],
        input[type="email"],
        input[type="password"],
        select,
        div.dropzone{
          width: 100%;
          height: get-vw(40px);
          border-radius: get-vw(5px);
          border: get-vw(1px) solid $form-style2-border;

          padding: get-vw(10px) get-vw(11px) get-vw(3px) get-vw(11px);
          background-color : transparent;
          font-size : get-vw(11px);
        }
        div.dropzone{
          display: flex;
          justify-content: center;
          align-items: center;
          height: get-vw(60px);
          text-align: center;
          cursor: pointer;

          font-weight: bold;

          padding: get-vw(10px) get-vw(11px);
        }

        &.multiselect {
          label{
            z-index: 9;
          }

          //multiselect wrapper
          > div{

            //fake input
            > div:first-of-type {
              border-radius: get-vw(5px);
              border: get-vw(1px) solid $form-style2-border;

              > div:first-of-type {
                min-height: get-vw(30px);

                padding-top: get-vw(15px);
              }
            }
          }

          input {
            height: auto;
          }
        }

        textarea{
          padding: get-vw(13px) get-vw(11px);
          font-size : get-vw(11px);

          width: 100%;
          min-height: get-vw(110px);

          border-radius: get-vw(5px);
          border: 1px solid $form-style2-border;
          background: #FFFFFF;

        }
      }

      &.type3{
        position: relative;
        margin-bottom : get-vw(15px);
        padding-top: get-vw(10px);

        label{
          position: absolute;
          z-index: 2;

          background-color : $white-color;
          color: $form-style3-label;
          font-size: 1rem;
          @extend .fw-medium;
          line-height: 1.5rem;

          padding: 0 get-vw(7px);

          top: get-vw(9px);
          left: get-vw(10px);
          transform: translateY(-50%);
        }

        input[type="text"],
        input[type="email"],
        input[type="password"],
        select,
        div.dropzone{
          width: 100%;
          height: get-vw(56px);
          border-radius: get-vw(8px);
          border: get-vw(1px) solid $form-style3-border;

          padding: 0 get-vw(16px);
          background-color : transparent;
          font-size : 1.3rem;
        }

        /*file drop input*/
        div.dropzone{
          cursor: pointer;
          padding: get-vw(12px) get-vw(20px) get-vw(12px) get-vw(90px);

          font-size : 1.08rem;
          border-color : $cta-color;
          color : $cta-color;

          height: auto;

          background-image : url("../images/mobile/carte_pro.svg");
          background-size : 27px auto;
          background-position: center left get-vw(30px);
          background-repeat: no-repeat;
        }

        /*phone input*/
        div.react-tel-input {
          .flag-dropdown{
            border: 0;
            background-color: transparent;
          }
          input {
            width: 100%;
            height: get-vw(56px);

            border-radius: get-vw(8px);
            border: get-vw(1px) solid $form-style3-border;
            background-color: transparent;
            font-size: 1.3rem;
          }
        }

        /*password input*/
        .inputPassword{
          position: relative;

          input{
            padding-right: get-vw(50px);
          }
          span{
            position: absolute;
            width: get-vw(24px);
            height: get-vw(24px);

            background-image: url("../images/mobile/eye_open.svg");
            background-repeat: no-repeat;
            background-size : 100% auto;

            right: get-vw(16px);
            top: 50%;

            transform: translateY(-50%);
            cursor: pointer;

            &.show{
              background-image: url("../images/mobile/eye_close.svg");
            }
          }
        }

        /* multiselect input */
        &.multiselect {
          label{
            z-index: 9;
          }

          //multiselect wrapper
          > div{

            //fake input
            > div:first-of-type {
              border-radius: get-vw(5px);
              border: get-vw(1px) solid $form-style2-border;

              > div:first-of-type {
                min-height: get-vw(56px);

                padding-top: 0;
              }
            }

            //menu
            > div:nth-of-type(2){
              z-index: 99;
            }
          }

          input {
            height: auto;
          }
        }

        textarea{
          padding: get-vw(13px) get-vw(11px);
          font-size : get-vw(11px);

          width: 100%;
          min-height: get-vw(110px);

          border-radius: get-vw(5px);
          border: 1px solid $form-style2-border;
          background: #FFFFFF;

        }
      }

      &.upload_wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;

        input{
          display: none;
        }

        .picture{
          overflow: hidden;
          width: get-vw(100px);
          height: get-vw(100px);

          border-radius : 100%;
          background-color : $form-upload-profil;
          /*margin-right: get-vw(7px);*/
          margin: auto;

          img{
            object-fit: cover;
            width: 100%;
            height: 100%;
          }

          > div{
            font-size : get-vw(40px);
            text-align: center;
            line-height: get-vw(100px);
            text-transform: uppercase;

            background-color : var(--profil-color);
            color : $white-color;
            @extend .fw-medium;
          }

        }

        /*button,
        label{
          @extend .ff-inter;
          @extend .fw-medium;
          color: $form-upload-color;
          text-align: center;
          font-size: get-vw(10px);
          line-height: normal;

          border-radius: 5px;
          border: 1px solid #6C7C93;
          background: #FFF;

          padding : get-vw(7px) get-vw(10px);

          cursor: pointer;

          &+button{
            margin-left: get-vw(10px);
          }

          &:disabled{
            cursor: not-allowed;
            color : $form-upload-disabled;
            border-color : $form-upload-disabled;
          }
        }*/
      }

      &.dd_upload{
        label{
          min-width: 0;
        }
      }

      &.column{
        div+div{
          margin-top : get-vw(15px);
        }
      }

      &.dropzone_wrapper{

        .dropzone_notice{
          font-size : 1.5rem;
          padding: get-vw(5px) get-vw(10px);

          &.success{
            color: $dropzone_notification_success;

            &:after{
              content: "";
              display: inline-block;

              width: get-vw(13px);
              height: get-vw(13px);

              background-color : $dropzone_notification_success;
              border-radius: 100%;

              background-image : url("../images/mobile/check_icon_white.svg");
              background-repeat: no-repeat;
              background-position: top get-vw(4px) center;
              background-size : get-vw(8px) auto;

              margin-left: get-vw(5px);
            }
          }
        }

        .dropzone_files{
          padding-left: get-vw(10px);
          font-size : get-vw(14px);

            .dropzone_delete{
              cursor: pointer;
              font-weight: bold;
              color : red;
            }
        }

      }

    }

    .form_row_title{
      padding-top: get-vw(20px);
      margin-bottom : get-vw(15px);
    }

    .checkbox_wrapper{

        input{
          position: absolute;
          z-index: -999;
          opacity: 0;
          text-indent: -99999px;
        }
  
        &.checkbox_cta{
  
          input + label{
            display: inline-block;
            height: get-vw(56px);
            line-height: get-vw(56px);
  
            border: get-vw(1px) solid $form-input-border;
            border-radius: get-vw(8px);
            background-color : $white-color;
  
            text-align: center;
            font-size : get-vw(16px);
            @extend .fw-medium;
            width: 100%;
  
            cursor: pointer;
  
            transition: 0.3s;
  
            box-shadow: 0 get-vw(4px) get-vw(2px) 0 rgba(25, 119, 243, 0.09);
          }
  
          input:checked + label{
            background-color : $cta-color;
            border-color : $cta-color;
            color : $white-color;
          }
  
        }

        &.checkbox_small_cta{

          text-align: center;
  
          input + label{
            display: inline-block;
            height: get-vw(30px);
            line-height: get-vw(30px);
  
            border: get-vw(1px) solid $cta-blue-bg;
            border-radius: get-vw(8px);
  
            text-align: center;
            font-size : get-vw(10px);
            color : $cta-blue-bg;
            @extend .fw-semibold;
            width: 100%;
            max-width: get-vw(150px);
  
            cursor: pointer;
  
            transition: 0.3s;
          }
  
          input:checked + label{
            background-color : $cta-color;
            border-color : $cta-color;
            color : $white-color;
          }
  
        }

        &.checkbox{
          input + label{
            position: relative;
            display: inline-block;

            padding-left: get-vw(25px);
            padding-top: get-vw(3px);
            font-weight: bold;

            &:before{
              position: absolute;
              left: 0;
              top: 0;

              content : "";
              display: inline-block;

              width: get-vw(20px);
              height: get-vw(20px);

              border : 1px solid $main-color;
              border-radius: get-vw(3px);
            }
          }

          input:checked + label:before{
            background-color : $main-color;
          }
        }

        &.checkbox_blue{
        input + label{
          position: relative;
          display: inline-block;

          padding-left: get-vw(25px);
          padding-top: 0;

          &:before{
            position: absolute;
            left: 0;
            top: 0;

            content : "";
            display: inline-block;

            width: get-vw(20px);
            height: get-vw(20px);

            border : 1px solid $main-color;
            border-radius: get-vw(3px);
          }
        }

        input:checked + label:before{
          background-color : $main-color;
        }
      }
    }

    .error{
      font-size : get-vw(12px);
      color: red;
    }
  }

  .choice-header-img {
    height: get-vw(200px);
    background: url("../images/mobile/start.png");
    background-size : contain;
    background-repeat: no-repeat;
    background-position: center;
  }

  //jumbotron
  .jumbotron{
    @extend .ff-inter;

    border: get-vw(1px) solid $profil-recruteur-color4;
    background: $profil-recruteur-color4;
    padding: get-vw(25px) get-vw(25px);
    text-align: center;
    margin-bottom: get-vw(20px);
    border-radius: get-vw(8px);
    color: $white-color;


    .jumbotron_title{
      margin-bottom : get-vw(10px);
      //text-decoration: underline;
      font-size : get-vw(18px);
    }
    .jumbotron_content{
      font-size : get-vw(14px);
    }

    &.yellow{
      background-color : $jumbotron-bg-yellow;
    }
  }

  //align
  .text-right{
    text-align: right;
  }
  .text-center{
    text-align: center;
  }
  .text-left{
    text-align: left;
  }

  //marge
  .mb1{
    margin-bottom: get-vw(5px);
  }
  .mb2{
    margin-bottom: get-vw(8px);
  }
  .mb3{
    margin-bottom: get-vw(10px);
  }

  .mt1{
    margin-top: get-vw(5px);
  }
  .mt2{
    margin-top: get-vw(8px);
  }
  .mt3{
    margin-top: get-vw(10px);
  }

  /* Base Class */
  .generic_data_block {
    background-color : $dashboard-block-bg;

    border-radius: get-vw(20px);
    //border: get-vw(1px) solid $dashboard-block-border;

    padding: get-vw(12px);
    margin-bottom : get-vw(20px);

    color : $main-color;
    font-size: 1.12rem;
    @extend .fw-medium;

    cursor: pointer;

    .content{
      .description{
        @extend .fw-thin;
      }
      .date{
        @extend .fw-thin;
      }

      &.vcentered{
        justify-content: center;
        display: flex;
        flex-direction: column;
      }

    }
    .tags{
      padding: get-vw(5px) 0;

      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      gap: get-vw(5px);

      span {
        background: $label-bg;
        color: $label-color;

        text-align: center;

        padding: 0 get-vw(10px);
        line-height: get-vw(12px);
        height: get-vw(12px);
        font-size: get-vw(8px);

        border-radius: get-vw(6px);
      }
    }
    &.empty{
      text-align: center;
      cursor: auto;
      padding: get-vw(20px) get-vw(12px);
    }
  }
  .generic_data_wrapper {
    padding: get-vw(20px) 0;
  }
  .generic_data_block_picture {
    width: get-vw(54px);
    height: get-vw(54px);

    background-color : $grey-color;
  }
  .generic_data_block_label {
    //position: relative;

    //@extend .ff-ribeye;
    //font-size : 2.5rem;
    //color : $main-color;
    //text-transform: uppercase;

    width: get-vw(54px);
    height: get-vw(54px);

    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: center;

    //span{
    //  position: absolute;
    //  left: get-vw(11px);
    //
    //  &:nth-of-type(1){
    //    top: get-vw(-5px);
    //  }
    //
    //  &:nth-of-type(2){
    //    top: get-vw(15px);
    //  }
    //}
  }

  /* CONNEXION / INSCRIPTION / MDP OUBLIE */
  .connection_wrapper {
    width: 100%;
    padding: get-vw(20px);


    .connection_link{
      margin-bottom: get-vw(30px);
      font-size: 1.16rem;

      a{
        color : $main-color;
        @extend .fw-medium;
        text-decoration: none;

        &#connection_link_reset{
          text-align: right;
        }
      }
    }

    .separator {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      opacity: 0.5;
      margin-bottom : get-vw(15px);

      div {
        flex-grow: 1;
        background-color: $form-separator-color;
        height: get-vw(1px);
      }
      span {
        font-size: get-vw(12px);
        color: $form-separator-color;
        padding: 0 get-vw(5px);
      }
    }

  }

  /* PROTECTED PAGE */

  /* block dashboard */
  .dashboard_block_wrapper{
    position: relative;
    @extend .generic_data_wrapper;

    .dashboard_block_more_link{
      position: absolute;

      color : $cta-blue-bg;
      top: get-vw(20px);
      right: 0;

      font-size : 1.16rem;
      text-decoration: none;
    }
    .dashboard_item{
      @extend .generic_data_block;

      &.dashboard_item_help,
      &.dashboard_item_switch{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap : get-vw(10px);

        cursor: pointer;

        .picture{
          @extend .generic_data_block_picture;
        }

        .item_label{
          @extend .generic_data_block_label;
        }

      }
    }

    .conversation_wrapper{
      box-shadow: none;
    }

  }

  /* block match */
  .match_block {
    @extend .generic_data_block;

    display: flex;
    flex-direction: row;
    gap : get-vw(14px);

    padding: get-vw(14px);

    .picture {
      @extend .generic_data_block_picture;
      border-radius: 100%;
      background-image : url("../images/mobile/heart-blue.svg");
      background-size : 100%;
      background-repeat: no-repeat;
      background-position: center;

      &.talent {
        background-image : url("../images/mobile/heart-yellow.svg");
      }
    }
    .content{
      flex-grow: 1;
    }
  }

  /* block collaborateur */
  .profil_talent_block{
    @extend .generic_data_block;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap : get-vw(10px);

    .picture{
      @extend .generic_data_block_picture;
      border-radius: 100%;

      overflow: hidden;
      width: get-vw(40px);
      height: get-vw(40px);

      img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

    }

    .content{
      flex-grow: 1;
    }
    .square{
      border-radius: get-vw(3px);
      background-color : $cta-blue-bg;

      width: get-vw(10px);
      height: get-vw(10px);
    }

  }

  /** DEPRECATED **/
  .dashboard_block{

    background-color : $dashboard-block-bg;

    border-radius: get-vw(10px);
    border: get-vw(1px) solid $dashboard-block-border;

    padding: get-vw(20px) get-vw(25px);
    margin-bottom : get-vw(20px);

    &.column{
      display: flex;
      flex-direction: row;
      gap : get-vw(20px);
    }

    &.detail_block{

      padding: get-vw(25px) get-vw(16px);

      .notification {
        @extend .ff-manrope;
        @extend .fw-semibold;

        font-size : get-vw(13px);
        text-align: center;

        border-radius : get-vw(5px);
        padding: get-vw(3px) get-vw(20px);
        margin-top: get-vw(-15px);
        margin-bottom: get-vw(15px);

        &.match{
          color : $mission_notification_match;
          background-color : $mission_notification_match_bg;
        }

        &.new_match{
          color : $mission_notification_new_match;
          background-color : $mission_notification_new_match_bg;
        }

        &.its_a_match{
          color : $mission_notification_its_a_match;
          background-color : $mission_notification_its_a_match_bg;
        }
      }

      .date{
        color: $cta-blue-bg;
        font-size: get-vw(12px);
        margin-bottom : get-vw(9px);
      }
      .title{
        color : $main-color;
        font-size: get-vw(14px);
        @extend .fw-semibold;
        margin-bottom : get-vw(9px);
      }
      .description{
        color: $black-color;
        font-size: get-vw(10px);
        line-height: get-vw(18px);
        @extend .fw-thin;

        margin-bottom: get-vw(10px);
      }
      .stat{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        > div{
          padding: get-vw(7px);
          flex-basis: 50%;
          span:first-child{
            display: block;
            color: $black-color;
            font-size: get-vw(12px);
            @extend .fw-medium;
          }
          span:last-child{
            display: block;
            color: $cta-blue-bg;
            font-size: get-vw(12px);
          }
        }
      }
      a{
        color : $main-color;
        font-size: get-vw(12px);
        text-decoration-line: underline;
      }

      .more{
        text-align: center;
      }
    }

    &.document_block{
      padding: get-vw(25px) get-vw(16px);

      .title{
        color : $main-color;
        @extend .fw-semibold;
        margin-bottom : get-vw(9px);
        font-size: get-vw(10px);
        line-height: get-vw(15px);
        letter-spacing: get-vw(0.2px);
      }

      .date{
        color: $main-color;
        font-size: get-vw(8px);
        margin-bottom : get-vw(9px);

        span{
          color: $skills-color;
        }

      }

      .stat{
        display: flex;
        flex-direction: row;
        gap: get-vw(13px);

        > div{

          //like and answer count
          &:nth-of-type(1)
          {
            background-repeat: no-repeat;
            background-position: left center;

            padding-left: get-vw(20px);

            color: $question-stat-color;
            @extend .ff-manrope;
            @extend .fw-semibold;
            font-size: get-vw(10px);

            line-height: get-vw(25px);
          }

          &:nth-of-type(1){
            background-image : url("../images/mobile/question_like.svg");
            background-size : get-vw(13px) auto;
          }

          //category
          &:nth-of-type(2){
            display: flex;
            gap: get-vw(10px);
            span:not(.more){
              display: block;
              color: $question-stat-cat-color;
              background-color : $question-stat-cat-bg;
              font-size: get-vw(8px);
              line-height: get-vw(20px);
              padding: get-vw(2px) get-vw(10px);
              border-radius : get-vw(8px);

              @extend .fw-semibold;
            }
            span.more{
              display: block;
              color: $question-stat-cat-more-color;
              background-color : $question-stat-cat-more-bg;
              font-size: get-vw(8px);
              @extend .fw-semibold;

              width: get-vw(23px);
              height: get-vw(23px);
              border-radius: 100%;
              text-align: center;
              line-height: get-vw(23px);
            }
          }
        }

      }
      a{
        color : $main-color;
        font-size: get-vw(12px);
        text-decoration-line: underline;
      }

      .more{
        text-align: center;
      }
    }

  }

  /* block Mission */
  .mission_block_wrapper {
    @extend .generic_data_wrapper;

    .match_item{
      @extend .generic_data_block;

      display: flex;
      flex-direction: row;
      gap : get-vw(10px);

      padding: get-vw(10px);

      .title{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        gap : get-vw(5px);

        &:before{
          content : "";
          display: inline-block;

          width: get-vw(15px);
          height: get-vw(15px);

          background-color : $main-color;
          border-radius : get-vw(2px);
        }
      }
    }
  }

  .step {
    font-size : 0.8rem;
    padding: get-vw(5px) get-vw(7px);
    color : $main-color;
    background: $grey-color;
    border-radius: get-vw(5px);

    &.step-pending {
      color : $label-color;
      background: $label-bg;
    }
    &.step-sent {
      color : $main-color;
      background: $grey-color;
    }
    &.step-accepted {
      color : $mission_notification_match;
      background: $mission_notification_match_bg;
    }
    &.step-agreement {
      color : $profil-candidat-color;
      background: $profil-candidat-color2;
    }
  }

  .item_label {
    @extend .generic_data_block_label;
    background-size: get-vw(50px) auto;
    background-image : url("../images/mobile/mission_icon_1.png");

    &.item_label_1 {
      background-image : url("../images/mobile/mission_icon_1.png");
    }
    &.item_label_2 {
      background-image : url("../images/mobile/mission_icon_2.png");
    }
    &.item_label_3 {
      background-image : url("../images/mobile/mission_icon_3.png");
    }
    &.item_label_4 {
      background-image : url("../images/mobile/mission_icon_4.png");
    }
  }

  .mission_block_item{
    @extend .generic_data_block;

    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap : get-vw(5px);

    cursor: pointer;

    .mission_block_item_inner {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap : get-vw(5px);
    }

    .content{
      flex-grow: 1;

      .title{
        font-size : get-vw(14px);
      }
      .subtitle{
        @extend .fw-thin;
        font-size : get-vw(12px);
      }
      .description{
        @extend .ff-nunito;

        span.reference{
          position: relative;
          display: inline-block;
          font-size : get-vw(12px);
          font-weight: 700;
          color : var(--profil-color4);
        }
        span.state{
          position: relative;
          color : $block-mission-secondary-color;
          font-size : get-vw(12px);

          padding-left: get-vw(9px);
          margin-left: get-vw(7px);

          &:before{
            content : "";
            display: block;

            position: absolute;

            left: 0;
            top: 50%;

            transform: translateY(-50%);

            width: get-vw(3px);
            height: get-vw(3px);

            border-radius : 100%;
            background-color : $block-mission-secondary-color;
          }

        }
      }
      .progression{
        position: absolute;
        right: get-vw(13px);
        top: 50%;

        transform: translateY(-50%);

        color : var(--profil-color3);
        background-color : var(--profil-color2);

        line-height: get-vw(35px);
        padding: 0 get-vw(10px);
        border-radius : get-vw(6px);
      }

    }

    .date {
      position: absolute;
      top: get-vw(10px);
      right: get-vw(10px);

      color: $block-help-date-color;
      font-size: 0.8rem;
    }
  }

  //mission item for recruteur
  .page_wrapper[data-profil="0"]{
    .item_label{
      background-image : url("../images/mobile/mission_recruteur_1.png");

      &.item_label_2 {
        transform: rotate(90deg);
      }
      &.item_label_3 {
        transform: rotate(180deg);
      }
      &.item_label_4 {
        transform: rotate(270deg);
      }
    }
  }

  /* block help */
  .help_block_item {
    @extend .generic_data_block;

    position: relative;

    .help_block_item_inner {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap : get-vw(5px);
    }

    .item_label {
      @extend .generic_data_block_label;
      background-size : get-vw(21px) auto;
      background-image : url("../images/mobile/picto_other2.png");
    }

    .icon_circle {
      width: get-vw(34px);
      height: get-vw(34px);

      background-repeat: no-repeat;
      background-size : 100%;
      background-position: center;
      background-image : url("../images/mobile/icon-circle-yellow.svg");
    }

    .content{
      flex-grow: 1;
      margin-left: get-vw(10px);

      .title{
        font-size: get-vw(14px);
      }
      .subtitle{
        font-size: get-vw(12px);
      }
      .description{
        @extend .ff-nunito;
        color : $block-mission-secondary-color;
        font-size : 1rem;

        span.type {
          color: $main-color;
          font-size: get-vw(12px);
        }

        span.reference{
          position: relative;
          display: inline-block;
          font-size : get-vw(12px);
          font-weight: 700;
          color : var(--profil-color4);
        }

        span.state {
          position: relative;
          color : $block-mission-secondary-color;
          font-size : get-vw(12px);

          padding-left: get-vw(9px);
          margin-left: get-vw(5px);

          &:before{
            content : "";
            display: block;

            position: absolute;

            left: 0;
            top: 50%;

            transform: translateY(-50%);

            width: get-vw(3px);
            height: get-vw(3px);

            border-radius : 100%;
            background-color : $block-mission-secondary-color;
          }

        }
      }
    }

    &.own{
      .item_label{
        background-image : url("../images/mobile/picto_other1.png");
      }

      .icon_circle {
        background-image : url("../images/mobile/icon-circle-blue.svg");
      }
    }

    &.small{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap : get-vw(5px);

      cursor: pointer;
    }

    &.full{

      cursor: auto;
      padding: get-vw(25px) get-vw(20px);

      .help_header{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap : get-vw(10px);
        width: 100%;

        .icon_circle {
          width: get-vw(40px);
          height: get-vw(40px);
        }

        .date{
          font-size : 1.08rem;
          color : $block-help-date-color;
          position: absolute;
          top: get-vw(10px);
          right: get-vw(15px);
        }

        .title{
          font-size : 1.16rem;
        }

        .subtitle{
          font-size : 1.08rem;
          color : $block-help-subtitle-color;
          @extend .fw-regular;
        }
      }
      .description{
        @extend .fw-thin;
        padding: get-vw(15px) 0;
      }
      .help_info{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: get-vw(5px);

        > div{
          h5{
            font-size : 1.16rem;
            color : $block-help-date-color;
            margin-bottom : 0;
          }
          span{
            font-size : 1rem;
            @extend .fw-regular;
          }
        }

      }
      .help_cta_wrapper{

        padding-top: get-vw(20px);
        margin-top: auto;

        display: flex;
        flex-direction: column;
        align-items: center;

        gap: get-vw(10px);

        button{
          max-width: get-vw(215px);
          height: get-vw(33px);
          line-height: get-vw(14px);
          border-radius: get-vw(17px);
          font-size : get-vw(12px);
          margin-bottom: 0;
        }

      }

      &.urgent{
        margin-top: get-vw(40px);
      }
    }

    .date {
      position: absolute;
      top: get-vw(10px);
      right: get-vw(10px);

      color: $block-help-date-color;
      font-size: 0.8rem;
    }
  }

  .urgent_label {
    position: absolute;
    right: get-vw(15px);
    top: get-vw(-20px);

    font-size : 1.166rem;
    padding: get-vw(7px) get-vw(10px);

    z-index: 0;
    color : $block-help-urgent-color;

    &:after{
      content : "";
      display: block;
      position: absolute;

      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      z-index: -1;
      background-color : $block-help-urgent-color-bg;
      opacity: 0.38;
      border-radius : get-vw(6px);
    }
  }

  /* block switch */
  .switch_block_wrapper{
    @extend .generic_data_wrapper;
  }
  .switch_block_item {
    @extend .generic_data_block;

    position: relative;
    cursor: pointer;

    .switch_block_item_inner {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap : get-vw(5px);
    }

    .item_label {
      @extend .generic_data_block_label;
      background-size : get-vw(21px) auto;
      background-image : url("../images/mobile/picto_other2.png");
    }
    .icon_circle {
      width: get-vw(34px);
      height: get-vw(34px);

      background-repeat: no-repeat;
      background-size : 100%;
      background-position: center;
      background-image : url("../images/mobile/icon-circle-yellow.svg");
    }

    .content{
      flex-grow: 1;
      margin-left: get-vw(10px);

      .title{
        font-size : get-vw(14px);
      }
      .subtitle{
        font-size: get-vw(12px);
      }
      .description{
        @extend .ff-nunito;
        color : $block-mission-secondary-color;
        font-size : 1rem;

        span.type {
          color: $main-color;
          font-size: get-vw(12px);
        }

        span.reference{
          position: relative;
          display: inline-block;
          font-size : get-vw(12px);
          font-weight: 700;
          color : var(--profil-color4);
        }

        span.state {
          position: relative;
          color : $block-mission-secondary-color;
          font-size : get-vw(14px);

          padding-left: get-vw(9px);
          margin-left: get-vw(5px);

          &:before{
            content : "";
            display: block;

            position: absolute;

            left: 0;
            top: 50%;

            transform: translateY(-50%);

            width: get-vw(3px);
            height: get-vw(3px);

            border-radius : 100%;
            background-color : $block-mission-secondary-color;
          }
        }
      }
    }

    &.own{
      .item_label{
        background-image : url("../images/mobile/picto_other1.png");
      }
      .icon_circle {
        background-image : url("../images/mobile/icon-circle-blue.svg");
      }

    }

    &.small{
      display: flex;
      flex-direction: row;
      align-items: center;
      gap : get-vw(5px);

      cursor: pointer;
    }

    &.full{
      flex-direction: column;
      cursor: auto;
      padding: get-vw(25px) get-vw(20px);

      .switch_header{
        display: flex;
        flex-direction: row;
        align-items: center;
        gap : get-vw(10px);
        width: 100%;

        .date{
          font-size : 1.08rem;
          color : $block-help-date-color;
          position: absolute;
          top: get-vw(10px);
          right: get-vw(15px);
        }

        .title{
          font-size : 1.16rem;
        }

        .subtitle{
          font-size : 1.08rem;
          color : $block-help-subtitle-color;
          @extend .fw-regular;
        }
      }
      .description{
        @extend .fw-thin;
        padding: get-vw(15px) 0;
      }
      .switch_info{
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: get-vw(5px);

        > div{
          h5{
            font-size : 1.16rem;
            color : $block-help-date-color;
            margin-bottom : 0;
          }
          span{
            font-size : 1rem;
            @extend .fw-regular;
          }
        }

      }
      .switch_cta_wrapper{

        margin-top: auto;
        padding-top: get-vw(20px);

        display: flex;
        flex-direction: column;
        align-items: center;

        gap: get-vw(10px);

        button{
          max-width: get-vw(215px);
          height: get-vw(33px);
          line-height: get-vw(14px);
          border-radius: get-vw(17px);
          font-size : get-vw(12px);
          margin-bottom: 0;
        }

      }

      &.urgent{
        margin-top: get-vw(40px);
      }
    }

    .date {
      position: absolute;
      top: get-vw(10px);
      right: get-vw(10px);

      color: $block-help-date-color;
      font-size: 0.8rem;
    }
  }

  .question_block_wrapper {
    .bloc_title_info {
      background: $profil-candidat-color;
      border-radius: get-vw(10px);
      padding: get-vw(20px) get-vw(15px);

      color: $white-color;
      font-size: 1.16rem;
    }
  }

  .question_block_item {
    background-color : $dashboard-block-bg;
    text-decoration: none;

    border-radius: get-vw(10px);
    border: get-vw(1px) solid $dashboard-block-border;

    padding: get-vw(15px) get-vw(20px);

    margin-bottom : get-vw(20px);

    .question_header_title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: get-vw(5px);
      margin-bottom: get-vw(5px);

      .picture {
        width: get-vw(24px);
        height: get-vw(24px);
      }
    }

    .title{
      color : $main-color;
      @extend .fw-semibold;
      margin-bottom : get-vw(2px);
      font-size: get-vw(12px);
      line-height: get-vw(16px);
      letter-spacing: get-vw(0.2px);
    }

    .date{
      color: $main-color;
      font-size: get-vw(8px);
      margin-bottom : get-vw(9px);

      span{
        color: $skills-color;
      }

    }

    .description{
      color: $main-color;
      font-size: get-vw(12px);
      line-height: get-vw(18px);
      @extend .fw-thin;

      margin-bottom: get-vw(10px);
    }

    .stat{
      display: flex;
      flex-direction: row;
      gap: get-vw(13px);

      > div{

        //like and answer count
        &:nth-of-type(1),
        &:nth-of-type(2)
        {
          background-repeat: no-repeat;
          background-position: left center;

          padding-left: get-vw(20px);

          color: $question-stat-color;
          @extend .ff-manrope;
          @extend .fw-semibold;
          font-size: get-vw(10px);

          line-height: get-vw(25px);
        }

        &:nth-of-type(1){
          background-image : url("../images/mobile/question_like.svg");
          background-size : get-vw(13px) auto;
        }

        &:nth-of-type(2){
          background-image : url("../images/mobile/question_answer_count.svg");
          background-size : get-vw(15px) auto;
        }

        //category
        &:nth-of-type(3){
          display: flex;
          gap: get-vw(10px);
          span:first-child{
            display: block;
            color: $question-stat-cat-color;
            background-color : $question-stat-cat-bg;
            font-size: get-vw(8px);
            line-height: get-vw(20px);
            padding: get-vw(2px) get-vw(10px);
            border-radius : get-vw(8px);

            @extend .fw-semibold;
          }
          span:last-child{
            display: block;
            color: $question-stat-cat-more-color;
            background-color : $question-stat-cat-more-bg;
            font-size: get-vw(8px);
            @extend .fw-semibold;

            width: get-vw(23px);
            height: get-vw(23px);
            border-radius: 100%;
            text-align: center;
            line-height: get-vw(23px);
          }
        }
      }
    }

    a{
      color : $main-color;
      font-size: get-vw(12px);
      text-decoration-line: underline;
    }

    .more{
      text-align: center;
    }

    &.own {
      background: $main-color;

      .title {
        color: $white-color;
      }

      .description {
        color: $white-color;
      }
    }

  }

  .single_question_header {
    background: $white-color;

    .picture {
      background-image : none !important;
      background-size : 100% auto;
      width: get-vw(40px);
      height: get-vw(40px);

      border-radius: get-vw(5px);
    }

    .single_question_header_title {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: get-vw(5px);

      .picture {
        width: get-vw(24px) !important;
        height: get-vw(24px) !important;
      }

      h2 {
        margin-bottom: 0;
      }
    }

    .single_item_detail_block_description {
      margin-top: get-vw(10px);
    }

    .stat {
      display: flex;
      flex-direction: row;
      gap: get-vw(10px);

      > div {

        //like and answer count
        &:nth-of-type(1),
        &:nth-of-type(2) {
          background-repeat: no-repeat;
          background-position: left center;

          padding-left: get-vw(20px);

          color: $question-stat-color;
          @extend .ff-manrope;
          @extend .fw-semibold;
          font-size: get-vw(10px);

          line-height: get-vw(25px);
        }

        &:nth-of-type(1) {
          background-image: url("../images/mobile/question_like.svg");
          background-size: get-vw(13px) auto;
        }

        &:nth-of-type(2) {
          background-image: url("../images/mobile/question_answer_count.svg");
          background-size: get-vw(15px) auto;
        }
      }
    }
  }

  .question_answer_item {
    padding: get-vw(10px);
    border-bottom: 1px solid $main-color;

    .question_answer_header {
      display: flex;
      flex-direction: row;
      gap: get-vw(5px);
      margin-bottom: get-vw(10px);

      .picture {
        width: get-vw(24px);
        height: get-vw(24px);
      }

      .question_answer_header_title {
        display: flex;
        flex-direction: row;
        align-items: center;
        //justify-content: space-between;
        gap: get-vw(10px);
      }

      .title {
        font-size: get-vw(14px);
      }
    }

    .description {
      font-size: get-vw(12px);
    }
  }

  /* block single match */
  .single_match_block{
    @extend .generic_data_wrapper;

    &+.single_match_block{
      padding-top: 0;
    }

    .content {
      .languages, .countries {
        > div {
          display: inline-flex;

        }
      }
    }
  }

  /* slinding panel from bottom */
  #sliding_panel {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    z-index: 9;

    height: 0;
    background-color: $sliding-panel-bg;
    border-radius: get-vw(32px) get-vw(32px) 0 0;

    transition: 0.5s;
    //padding: 0 get-vw(35px);

    &.show {
      height: calc(100% - get-vw(80px));
    }
  }

  #modal_panel {
    z-index: 9;

    background-color: $sliding-panel-bg;
    border-radius: get-vw(32px) get-vw(32px) 0 0;
  }

  #sliding_panel_body{
    padding: get-vw(30px) get-vw(35px);
    overflow: auto;
    max-height: 100%;
  }

  #sliding_panel_close{
    position: absolute;
    top: get-vw(17px);
    right: get-vw(19px);

    background-image : url("../images/mobile/sliding_panel_cta_close.svg");
    background-size : 100% auto;
    width: get-vw(18px);
    height: get-vw(18px);
  }


  /* BLOCK PROFILE */
  .profil_block{
    margin-bottom: get-vw(40px);
  }

  /* BLOC competence */
  .profilDataBlock{
      position: relative;
      border-radius: get-vw(15px);
      border: 1px solid $form-style2-border;
      background: #FFF;
      box-shadow: 0 get-vw(3px) get-vw(4px) 0 rgba(227, 227, 227, 0.29);

      margin-bottom : get-vw(7px);
      padding: get-vw(15px);

      .title{
        color: $main-color;
        font-size: get-vw(12px);
        letter-spacing: get-vw(0.24px);
        @extend .ff-poppins;
        @extend .fw-medium;

        padding-right: get-vw(50px);
        line-height: normal;
      }

      .experience,
      .international{
        color : $skills-color;
        @extend .ff-poppins;
        @extend .fw-medium;
        font-size: get-vw(12px);
        line-height: get-vw(12px);
        letter-spacing: get-vw(0.2px);

        padding : get-vw(3px) 0;
      }

      .description{
        color: $main-color;
        font-size: get-vw(12px);
        @extend .fw-thin;
        margin-top: get-vw(5px);
      }

      .subarea {
        padding: get-vw(5px) 0;

        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        gap: get-vw(5px);

        span {
          background: $label-bg;
          color: $label-color;

          text-align: center;

          padding: 0 get-vw(10px);
          line-height: get-vw(18px);
          height: get-vw(18px);
          font-size: get-vw(12px);

          border-radius: get-vw(6px);
        }
      }

      .cta_wrapper{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;

        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        gap: get-vw(5px);

        padding: get-vw(5px) get-vw(10px);

        .cta{
          font-size : 0;

          &.edit{
            background-image : url("../images/mobile/edit.svg");
            background-position: center;
            background-size : 100% auto;
            background-repeat: no-repeat;

            width: get-vw(20px);
            height: get-vw(20px);
          }

          &.delete{
            background-image : url("../images/mobile/delete_cross.svg");
            background-position: center;
            background-size : 100% auto;
            background-repeat: no-repeat;

            width: get-vw(20px);
            height: get-vw(20px);
          }
        }

      }
    }

  /* BLOC notification */
  .notification_block_wrapper{
    @extend .generic_data_wrapper;

    .notification_item{
      @extend .generic_data_block;

      display: flex;
      flex-direction: row;
      gap : get-vw(10px);

      padding: get-vw(20px);
      margin-bottom: get-vw(10px);

      .picture{
        @extend .generic_data_block_picture;
        width: get-vw(50px);
      }

      .content {
        flex: 1;

        .title {
          font-size: get-vw(14px);
        }

        .description {
          font-size: get-vw(12px);
          margin-top: get-vw(5px);
          margin-bottom: get-vw(10px);
        }

        .date {
          font-size: get-vw(10px);
        }
      }
      &.not-read {
        background: $notification-bg;
      }

      .actions {
        //display: flex;
        //align-items: center;

        .delete {
          background-image : url("../images/mobile/delete_cross.svg");
          background-position: center;
          background-size : 100% auto;
          background-repeat: no-repeat;

          width: get-vw(20px);
          height: get-vw(20px);
        }
      }
    }
  }

  /* Availability block */
  .availability_checkbox{

    background-image : url("../images/mobile/calendar.svg");
    background-size : get-vw(30px) auto;
    background-position: get-vw(18px) center;
    background-repeat: no-repeat;

    background-color : $availability-wrapper-bg;

    padding: get-vw(12px) get-vw(15px) get-vw(16px) get-vw(67px);

    margin: get-vw(18px) auto;

    .title{
      color: $main-color;
      @extend .fw-medium;
      font-size: get-vw(10px);
      line-height: get-vw(20px);

      margin-bottom : get-vw(10px);
    }

    .checkbox_availability_wrapper{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      gap: get-vw(15px);


      input{
        opacity: 0;
        position: absolute;
        z-index: -999;
      }

      input + label{
        display: inline-block;

        padding : get-vw(6px) get-vw(7px);
        border-radius : get-vw(5px);

        color: $cta-disabled-blue-color;
        border: get-vw(1px) solid $cta-disabled-blue-border;

        text-align: center;
        @extend .ff-inter;
        @extend .fw-medium;
        font-size: get-vw(10px);

        background-color : $cta-disabled-blue-bg;
        cursor: pointer;
      }

      input:checked + label{
        color: $cta-blue-color;
        border: get-vw(1px) solid $cta-blue-bg;
        background-color : $cta-blue-bg;
      }
    }
  }

  /* filter button bar*/
  .button_bar{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    gap: get-vw(15px);

    padding: get-vw(20px) 0;

    .button_item{
      input{
        position: absolute;
        opacity: 0;
        z-index: -999;
      }

      input + label{
        display: block;
        @extend .ff-inter;
        @extend .fw-medium;
        text-align: center;
        font-size: get-vw(10px);

        text-decoration: none;

        background-color : $buttonbar-inactive-bg;
        color : $buttonbar-inactive-color;
        padding: get-vw(5px) get-vw(10px);

        border-radius: get-vw(15px);
        cursor: pointer;
      }

      input:checked + label{
        background-color : $buttonbar-active-bg;
        color : $buttonbar-active-color;
      }
    }
  }

  //new filter bar
  .filterbar_wrapper{
    position: relative;
    z-index: 0;

    margin-bottom: get-vw(20px);

    &:after{
      content: "";
      display: block;

      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;

      width: 100%;
      height: get-vw(4px);
      background-color : $buttonbar-inactive-color;
      opacity: 0.2;
    }

    //inactive item
    ul{
      li{
        color : $buttonbar-inactive-color !important;
        padding: get-vw(12px) get-vw(15px) !important;
        font-size : 1.16rem;
      }
      &+div{
        background: $buttonbar-active-bg !important;
        height: get-vw(4px) !important;
        z-index: 9;
      }
    }

    //ActiveItem
    @for $i from 0 through 99 {
      &[index="#{$i}"]ul li:nth-of-type(#{$i+1}){
        color : $buttonbar-active-color  !important;
      }
    }

  }

  /* Intro bloc with title and subtitle */
  .bloc_title_info{
    margin-bottom : get-vw(20px);

    p{
      color: $generic_bloc_secondary_color;
      font-size: 1.2rem;
      @extend .fw-thin;
      margin-bottom : 0;
    }
  }

  /* barre de recherche */
  .searchbar_filter{
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    margin-top : get-vw(10px);
    margin-bottom : get-vw(10px);

    @extend .ff-manrope;
    @extend .fw-semibold;

    font-size: get-vw(10px);
    letter-spacing: get-vw(0.2px);

    input.searchbar{
      background-image : url("../images/mobile/search_glass.svg");
      background-size: get-vw(20px) auto;
      background-repeat: no-repeat;
      background-position: left get-vw(12px) center;

      border: 0;
      border-radius: get-vw(6px);
      box-shadow: 0 get-vw(3px) get-vw(4px) 0 rgba(227, 227, 227, 0.29);

      height: get-vw(39px);
      width: 100%;



      padding-left: get-vw(40px);
      padding-right: get-vw(10px);

      margin-bottom : get-vw(13px);

    }
    select{
      border-radius: get-vw(6px);
      background-color : $white-color;
      border: 0;
      box-shadow: 0 get-vw(3px) get-vw(4px) 0 rgba(227, 227, 227, 0.29);

      max-width: get-vw(150px);

      -webkit-appearance: none;
      -moz-appearance: none;

      background-image : url("../images/mobile/select_dropdown.svg");
      background-size: get-vw(8px) auto;
      background-repeat: no-repeat;
      background-position: right get-vw(10px) center;
      padding: get-vw(5px) get-vw(30px) get-vw(5px) get-vw(12px);
    }
  }

  /* single content for mission, help, switch*/
  .single_item_wrapper {
    position: relative;

    .single_item_header {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      gap : get-vw(10px);

      color : $generic_bloc_main_color;

      position: relative;

      .cta_wrapper{
        flex-basis: 100%;
      }

      .picture{
        background-image : url("../images/mobile/mission_avatar.png");
        background-size : 100% auto;
        width: get-vw(70px);
        height: get-vw(70px);

        border-radius: get-vw(5px);
      }

      .content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        flex-grow: 1;

        .title{
          font-size: get-vw(14px);
          @extend .fw-semibold;
        }
        .description{
          color : $generic_bloc_secondary_color;
        }
        .other {
          display: flex;
          flex-direction: row;
          justify-content: space-between;

          .detail{
            text-decoration: underline;
            cursor: pointer;
            font-weight: bold;
          }
        }
      }

      .urgent_label {
        top: get-vw(0px);
        right: get-vw(0px);
      }

      .single_item_header_cta {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .single_item_header_actions {
          //position: absolute;
          //top: get-vw(5px);
          //right: get-vw(5px);

          display: flex;
          flex-direction: row;
          align-items: center;
          gap: get-vw(5px);
        }
      }
    }

    .other_single_item_header{
      position: relative;

      display: flex;
      flex-direction: column;
      gap : get-vw(10px);

      color : $generic_bloc_main_color;

      //padding-top : get-vw(140px);
      margin-bottom: get-vw(150px);

      .picture {
        position: absolute;
        left: $content-x-padding * -1;
        top: 0;

        background-image : url("../images/mobile/singleItemHeader.png");
        background-size : 100% auto;
        height: get-vw(120px);
        width: 100vw;
      }

      .content{
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        flex-grow: 1;

        .title{
          font-size: get-vw(14px);
          @extend .fw-semibold;
          margin-bottom: get-vw(15px);
        }

        .other_single_item_data{
          display: flex;
          flex-direction: row;
          gap : get-vw(10px);

          margin-bottom: get-vw(5px);

          label{
            @extend .fw-semibold;
          }
          div{
            flex-grow: 1;
            text-align: right;
          }
        }

        .description{
          flex-wrap: wrap;
          gap: 0;

          label{ width : 100%; }
          div{text-align: left;}
        }

      }
    }
    .single_item_details{
      .single_item_detail_block{
        margin-bottom : get-vw(20px);

        h3{
          margin-bottom : get-vw(5px);
        }
        p {
          line-height: get-vw(18px);
        }
      }

      .single_item_detail_block_description {
        white-space: pre-line;
      }
    }
    .single_item_cta_wrapper{
      //padding-top: get-vw(30px);
      position: fixed;
      bottom: 0;
      left: 0;

      width: 100%;
      padding: get-vw(20px) get-vw(20px);
    }

    .single_match_header {
      display: flex;
      flex-direction: row;

      .single_match_header_detail {
        margin-left: get-vw(20px);
      }
    }
  }


  .single_item_wrapper_border {

    .single_item_header {
      padding: get-vw(20px);
      border-radius: get-vw(15px);
      margin-bottom: get-vw(15px);
      position: relative;
      
      flex-direction: column;
    }
  }

  /* match listing for single content mission, help, switch*/
  .single_item_match_listing_wrapper {

    .title {
      text-align: center;
    }

    .match_item{
      @extend .generic_data_block;

      display: flex;
      flex-direction: row;
      gap : get-vw(10px);

      padding: get-vw(15px);

      .title{
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        gap : get-vw(5px);

        //&:before{
        //  content : "";
        //  display: inline-block;
        //
        //  width: get-vw(15px);
        //  height: get-vw(15px);
        //
        //  background-color : $main-color;
        //  border-radius : get-vw(2px);
        //}
      }

      &.liked{
        background-image : url("../images/mobile/match_like_active.svg");
        background-repeat: no-repeat;
        background-position: top get-vw(5px) right get-vw(5px);
        background-size: get-vw(24px) auto;
      }
    }
  }

  /* popup confirm */
  .popup_confirm{
    .bloc_title_info{
      h2{font-size : 1.8rem;}
      p{
        font-size: 1.3rem;
        color : $main-color;
      }
    }
    .cta_wrapper{
      display: flex;
      flex-direction: row;
      justify-content: center;

      gap : get-vw(10px);

      button{
        flex-basis: 50%;
        flex-grow: 0;
      }
    }
  }

  /* Messagerie */
  .conversation_wrapper{
    @extend .generic_data_wrapper;
  }
  .conversation_block_item{
    @extend .generic_data_block;

    position: relative;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap : get-vw(10px);

    cursor: pointer;

    .picture{
      //@extend .generic_data_block_picture;
      border-radius: 100%;
      overflow: hidden;
      min-width: 50px;
    }

    .content {
      flex: 1;

      .title {

      }

      .subtitle {
        color: $profil-candidat-color;
        font-size: get-vw(10px);
      }

      div.last_message{
        @extend .fw-thin;
        //overflow: hidden;
      }
    }

    .extra {
      text-align: right;

      .time {
        @extend .fw-thin;
        font-size: get-vw(10px);

        display: flex;
        flex-direction: column;
      }

      .author-info {
        @extend .fw-regular;
        font-size: get-vw(14px);
      }
    }

  }

  /*Chat*/
  #page_conversation_single{

    .cs-main-container{
      margin-left: -$content-x-padding;
      margin-right: -$content-x-padding;
      border: 0;
      background-color : transparent;


      .cs-chat-container{
        max-height: calc(100vh - get-vw(85px)); // full height minus header height
      }


      .cs-conversation-header{
        background-color : $background-color;
        align-items: center;

        .cs-conversation-header__back {
          display: none;
        }

        .cs-conversation-header__content > div{
          background-color : transparent;
        }

        .cs-conversation-header__actions {
          .cs-button {
            font-size:  get-vw(20px);
            color: $main-color;
            border: 1px solid $grey-color;
            border-radius: 50%;
            width: get-vw(40px);
            height: get-vw(40px);
            padding-top: get-vw(5px);
          }
        }
      }

      .cs-message-list{
        padding-top : get-vw(20px);
        background-color : $background-color;
        overflow: auto;
      }
      .cs-message-separator{
        background-color : transparent;
        color : $chat-separator-color;
        font-size : 0.9rem;
        @extend .ff-poppins;
        @extend .fw-semibold;

        &:before,&:after{
          opacity: 0;
        }
      }
      .cs-message{

        .cs-message__avatar{
          width: get-vw(40px);
          height: get-vw(40px);

          line-height: get-vw(40px);
          text-align: center;

          background-color : $main-color;
          border-radius : 100%;

          font-size : 1rem;
          text-transform: uppercase;
          font-weight: bold;
          color : $white-color;

          > div{
            width: 100%;
            height: 100%;

            min-height: auto;
            min-width: auto;
          }
        }

        .cs-message__content-wrapper{
          border-radius : get-vw(5px);
          .cs-message__content{
            background-color : transparent;
            font-size : 1.1rem;
            padding: get-vw(5px) get-vw(10px);
          }

          .cs-message__footer{
            font-size : 0.75rem;
            padding: 0 get-vw(10px) get-vw(5px) get-vw(10px);

            .cs-message__sent-time{
              padding: 0;
            }
          }
        }

        //incoming message
        &.cs-message--incoming .cs-message__content-wrapper {
          background-color : $chat-message-incoming-bg;
          border-top-left-radius: 0;


          .cs-message__content{
            color : $chat-message-incoming-color;
          }
          .cs-message__footer, .cs-message__sent-time{
            color : $chat-message-incoming-color;
            margin-left: 0;
            order : 1;
          }
        }

        //outgoing message
        &.cs-message--outgoing .cs-message__content-wrapper {
          background-color : $chat-message-outgoing-bg;
          border-top-right-radius: 0;

          .cs-message__content{
            color : $chat-message-outgoing-color;
          }
          .cs-message__footer, .cs-message__sent-time{
            color : $chat-message-outgoing-color;
          }
        }
      }

      .cs-message-input{
        padding: get-vw(15px);
        border: 0;
        background-color : $background-color;
        .cs-message-input__tools{
          display: none;
        }
        .cs-message-input__content-editor-wrapper{
          background-color : $chat-input-bg-color;
          .cs-message-input__content-editor{
            background-color : $chat-input-bg-color;
            line-height: get-vw(40px);
            font-size : 1.3rem;
          }
        }
      }
    }
  }

  .video_wrapper {
    background: $white-color;
    border-radius: get-vw(5px);
    padding: get-vw(20px);
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    //video {
    //  width: 80%;
    //  margin: auto;
    //}

    .video_inner {
      position: relative;

      width: 100%;
      height: 100%;

      .localVideo {
      }

      .corner {
        position: absolute;
        top: get-vw(20px);
        right: get-vw(20px);

        width: get-vw(300px);
        height: get-vw(150px);
      }

      .video_actions {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        gap: get-vw(5px);

        padding: get-vw(5px);

        z-index: 99;

        .endCall {
          background-image: url("../images/mobile/end-call-icon.svg");
          background-size: 100% auto;
          width: get-vw(30px);
          height: get-vw(30px);

          cursor: pointer;
        }

        .mute {
          background-image: url("../images/mobile/mute.svg");
          background-size: 100% auto;
          width: get-vw(30px);
          height: get-vw(30px);

          cursor: pointer;

          background-color: white;
          border-radius: 50%;

          &.unmute {
            background-image: url("../images/mobile/unmute.svg");
          }
        }

        .camera {
          background-image: url("../images/mobile/video.png");
          background-size: 100% auto;
          width: get-vw(30px);
          height: get-vw(30px);

          cursor: pointer;

          background-color: white;
          border-radius: 50%;

          &.cameraOff {
            background-image: url("../images/mobile/no-video.png");
          }
        }
      }
    }
  }

  /*Subscriptions*/
  .subscription_block_item {
    display: flex;
    flex-direction: row;

    &.selectable {
      background: $white-color;
    }

    #code {
      text-transform: uppercase;
    }

    padding: get-vw(18px);
    border-radius : get-vw(8px);
    border : get-vw(1px) solid $subscription_block_border;

    margin-bottom : get-vw(10px);
    cursor: pointer;

    .input{
      padding-right: get-vw(20px);

      input{
        position: absolute;
        z-index: -9999;
      }

      input+label{
        display: inline-block;
        width: get-vw(24px);
        height: get-vw(24px);

        border-radius: 100%;
        border: get-vw(2px) solid $subscription_block_border;
      }

      input:checked+label{
        background-color : $subscription_block_active_border;
        border-color : $subscription_block_active_border;

        background-image : url("../images/mobile/subscription_check.svg");
        background-size : get-vw(10px) auto;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
    .content{
      flex-grow: 1;

      .title{
        font-size : 1.16rem;
        color : $subscription_block_title;
        @extend .fw-medium;
      }

      .description{
        color : $subscription_block_description;
        //white-space: pre-line;
      }

      .details {
        margin-top: get-vw(10px);
        white-space: pre-line;
        color : $subscription_block_detail;
      }

      .price{
        text-align: right;
        font-size : 1.33rem;
        color : $subscription_block_title;
        padding-top: get-vw(12px);
        span{
          @extend .fw-bold;
          font-size : 1.66rem;
        }
      }
    }

    &.active{
      border-color : $subscription_block_active_border;
    }
  }

  .subscription_code_block {
    .content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
    }
  }

  .subscription_state {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .subscription_state_text {
      font-size: get-vw(16px);
      color: $main-color;
    }
  }

  .subscription_info {
    margin: get-vw(20px) auto;
    padding: get-vw(30px);
    border-radius: 20px;
    background: $white-color;

    .subscription_info_text {
      font-size: get-vw(20px);
      color: $main-color;

      span {
        font-weight: 600;
        color: $profil-candidat-color;
      }

      &.small {
        font-size: get-vw(16px);
      }
    }
  }

  .subscription_checkout_form {
    margin: get-vw(20px) auto;
  }

  .subscription_extra_wrapper {
    margin-bottom: get-vw(15px);

    .subscription_extra {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      margin-top: get-vw(5px);
      background: $white-color;
      padding: get-vw(8px);

      .subscription_extra_label {
        @extend .ff-poppins;
        @extend .fw-medium;
        font-size: get-vw(12px);
        color: $main-color;
      }

      .subscription_extra_value {
        @extend .ff-poppins;
        @extend .fw-semibold;
        font-size: get-vw(14px);
        color: $main-color;
      }
    }
  }
}

header{
  position: relative;
  height: get-vw(85px);
  padding: 0 $content-x-padding;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  flex-grow: 0;
  flex-shrink: 0;

  gap : get-vw(10px);

  .logo{
    position: absolute;

    background-image: url("../images/mobile/header_logo.png");
    background-size : 100% auto;
    background-repeat: no-repeat;

    width: get-vw(110);
    height: get-vw(40);

    left: 50%;
    transform: translateX(-50%);

    margin: auto;
  }

  .header-login-right {
    display: none;
  }

  .header-inline-menu {
    display: none;
  }

  #burger_menu{
    background-image : url("../images/mobile/header_burger.svg");
    background-size : 100% auto;
    width: get-vw(12px);
    height: get-vw(10px);

    cursor: pointer;
  }
  #notification{
    background-image : url("../images/mobile/header_notification.svg");
    background-size : 100% auto;
    background-repeat: no-repeat;
    width: get-vw(18px);
    height: get-vw(20px);

    cursor: pointer;
    margin-left: auto;

    position: relative;

    &.notification-new:after {
      content: '';
      position: absolute;
      top: get-vw(-3px);
      right: get-vw(-2px);

      width: get-vw(10px);
      height: get-vw(10px);

      border-radius: 50%;

      background: $dot-red;
    }
  }

  #logout{
    background-image : url("../images/mobile/header_logout.png");
    background-size : 100% auto;
    background-repeat: no-repeat;
    width: get-vw(24px);
    height: get-vw(24px);

    cursor: pointer;
  }

  #profile{
    overflow: hidden;
    width: get-vw(32px);
    height: get-vw(32px);

    border-radius : get-vw(8px);
    background-color : $form-upload-profil;
    margin-right: get-vw(7px);

    img{
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  #mobile_menu_wrapper{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 999;

    width: 0;
    height: 100vh;

    background-color : $white-color;

    transition: 0.5s;

    padding-top: get-vw(85px);
    padding-bottom: get-vw(150px);

    overflow: hidden;

    #mobile_menu_close{
      position: absolute;
      display: block;

      background-color : transparent;
      border: 0;
      outline: 0;

      background-image : url("../images/mobile/header_burger_close.svg");
      background-size : 100% auto;

      width: get-vw(20px);
      height: get-vw(20px);

      top: get-vw(32px);
      left: get-vw(25px);
    }
    nav{
      overflow: hidden;

      a{
        display: block;
        color: $main-color;
        font-size: get-vw(14px);
        text-decoration: none;

        padding: get-vw(10px) 0;

        border-bottom: get-vw(1px) solid $main-color;

        word-break: keep-all;
        white-space: nowrap;
      }
    }

    &.open{
      width: 80%;
      padding-left: get-vw(28px);
      padding-right: get-vw(20px);
    }
  }

}


/* PAGES */
#page_404_wrapper{
  .content_wrapper{
    @extend .content_wrapper_vertically_centered;
  }
}

#page_connexion_wrapper,
#page_forgot-password_wrapper,
#page_reset-password_wrapper,
#page_inscription_wrapper {
  background-color : $white-color;

  .content_wrapper{
    //@extend .content_wrapper_vertically_centered;
  }

  header {

    .logo {
      width: get-vw(172px);
      height: get-vw(60px);
    }
  }
}

#page_profile_wrapper {
  background-color : $white-color;


}

.user_profile_type {
  display: inline-block;
  line-height: get-vw(35px);
  font-size : 1.16rem;
  font-weight: 500 !important;
  @extend .fw-medium;

  padding: 0 get-vw(10px);
  border-radius: get-vw(6px);

  background-color : var(--profil-color2);
  color : var(--profil-color);
}

#page_dashboard_wrapper {

  #dashboard_stat_block{
    display: flex;
    justify-content: space-between;

    margin-bottom : get-vw(15px);

    .dashboard_item_stat{
      text-align: center;
      flex-basis: 25%;
      color : $main-color;

      > div{
        background-size : 100% auto;
        background-repeat: no-repeat;

        width: get-vw(92px);
        height: get-vw(92px);
        line-height: get-vw(92px);

        font-size : get-vw(40px);

        margin : 0 auto get-vw(5px) auto;
        @extend .fw-medium;
      }
    }
  }

  //profil candidat
  &[data-profil="1"]{
    #dashboard_stat_block .dashboard_item_stat{
      &:nth-of-type(1) > div {background-image :url("../images/mobile/stat_gold1.png");}
      &:nth-of-type(2) > div {background-image :url("../images/mobile/stat_gold2.png");}
      &:nth-of-type(3) > div {background-image :url("../images/mobile/stat_gold3.png");}
    }
  }

  //profil recruteur
  &[data-profil="0"]{
    #dashboard_stat_block .dashboard_item_stat{
      &:nth-of-type(1) > div {background-image :url("../images/mobile/stat_blue1.png");}
      &:nth-of-type(2) > div {background-image :url("../images/mobile/stat_blue2.png");}
      &:nth-of-type(3) > div {background-image :url("../images/mobile/stat_blue3.png");}
    }
  }
}

.user_picture{
  margin-bottom : get-vw(20px);

  .picture{
    overflow: hidden;
    width: get-vw(75px);
    height: get-vw(75px);

    border-radius : 100%;
    background-color : $form-upload-profil;
    margin: 0 auto get-vw(10px) auto;

    img{
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .name{
    color : $main-color;
    font-weight: bold;
    text-align: center;

    font-size : 1.66rem;
  }
}

#profilemenu_page_wrapper{

  padding-top : get-vw(85px);
  position: relative;
  z-index: 0;

  nav{
      a{
        display: block;
        background-color : $white-color;
        color : $main-color;

        height: get-vw(64px);
        line-height: get-vw(64px);
        margin-bottom : get-vw(15px);

        padding: 0 get-vw(15px);

        text-decoration: none;
        box-shadow: 0 get-vw(4px) get-vw(4px) 0 $grey-color;

        border-radius: get-vw(15px);

        @extend .fw-medium;
        font-size : 1.33rem;

        span{
          @extend .ff-ribeye;
          font-size : 2.08rem;
          display: inline-block;

          background-image : url("../images/mobile/profile_menu_picto1.svg");
          background-repeat: no-repeat;
          background-position: center bottom get-vw(15px);
          background-size: 100% auto;

          width: get-vw(50px);
          text-align: center;
          margin-right: get-vw(7px);
        }

        &.logout{
          padding-left: get-vw(72px);
          background-image : url("../images/mobile/profile_menu_logout.svg");
          background-repeat: no-repeat;
          background-position: left get-vw(20px) center;
          background-size: get-vw(35px) auto;
        }

        &.profile_switch{
          display: flex;
          flex-direction: row;
          align-items: center;
          box-shadow: none;

          @extend .ff-manrope;
          line-height: 1.66rem;

          background-position: center right get-vw(15px);
          background-repeat: no-repeat;
          background-size: get-vw(35px) auto;
        }
      }
  }

  &:before{
    content: "";
    display: block;

    position: absolute;
    top: 0;
    left: $content-x-padding * -1;
    right: $content-x-padding * -1;
    z-index: -1;

    height: get-vw(240px);

    background-size : 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
  }

  &.recruiter {
    &:before{background-image : url("../images/mobile/profile_menu_header1.png");}
    nav a span { background-image: url("../images/mobile/profile_menu_picto1.svg"); }
    nav a.profile_switch {
      background-image: url("../images/mobile/profile_menu_switch2.svg");
      background-color : $profil-candidat-color2;
      color : $profil-candidat-color4;
    }
  }
  &.candidate {
    &:before{background-image : url("../images/mobile/profile_menu_header2.png");}
    nav a span { background-image: url("../images/mobile/profile_menu_picto2.svg"); }
    nav a.profile_switch {
      background-image: url("../images/mobile/profile_menu_switch1.svg");
      background-color : $profil-recruteur-color2;
      color : $profil-recruteur-color4;
    }
  }

}

#match_header{
  display: flex;
  flex-direction: row;
  gap: get-vw(5px);

  .cta.return{
    margin-right: auto;
  }
}

#match_details{
  .content {
    font-size: get-vw(14px);

    > div{
      margin-bottom: get-vw(5px);
    }
  }
}

#single_match_wrapper{

  padding-bottom: get-vw(80px);

  #cta_wrapper{
    position: fixed;
    bottom: 0;
    left: 0;

    width: 100%;
    padding: get-vw(20px) get-vw(40px);
  }

  .linking_step_wrapper {
    padding: get-vw(10px);
  }
}

#community_category {
  margin: get-vw(10px) 0;

  .category_filters {
    display: flex;
    flex-direction: column;
    gap: get-vw(5px);

    .cta {
      border-radius: get-vw(10px);
    }
  }
}

#community_wrapper {
  .cta {
    &.small {
      font-size: 1rem;
      line-height: 40px;
      padding: 0 20px;
    }
  }

  .searchbar_filter {
    margin-top: get-vw(10px);
    margin-bottom: get-vw(5px);
  }

  .content_block {
    margin-top: get-vw(20px);
  }
}

/* OTHER */
.slick-dots {
  position: static;
}

.company_list {
  margin: get-vw(10px) 0 get-vw(30px) 0;
  background: $white-color;

  .company_item {
    padding: get-vw(10px);
    border-bottom: get-vw(1px) solid $main-color;

    &.company_item_add {
      border-bottom: 0;
    }
  }
}

.footer-logout {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

#page_acts_wrapper {
  .content_header {
    margin-top: get-vw(10px);
  }
}

.act_block_item {
  background-color : $dashboard-block-bg;
  text-decoration: none;
  position: relative;
  border-radius: get-vw(10px);
  border: get-vw(1px) solid $dashboard-block-border;

  padding: get-vw(15px) get-vw(20px);

  margin-bottom : get-vw(20px);

  .question_header_title {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: get-vw(5px);
    margin-bottom: get-vw(5px);

    .picture {
      width: get-vw(24px);
      height: get-vw(24px);
    }
  }

  .title{
    color : $main-color;
    @extend .fw-semibold;
    margin-bottom : get-vw(2px);
    font-size: get-vw(12px);
    line-height: get-vw(16px);
    letter-spacing: get-vw(0.2px);
  }

  .date{
    color: $main-color;
    font-size: get-vw(8px);
    margin-bottom : get-vw(9px);

    span{
      color: $skills-color;
    }

  }

  .description{
    color: $main-color;
    font-size: get-vw(12px);
    line-height: get-vw(18px);
    @extend .fw-thin;

    margin-bottom: get-vw(10px);
  }

  .file {
    text-decoration: underline;
  }

  .cta_wrapper {
    position: absolute;
    top: get-vw(5px);
    right: get-vw(5px);
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    gap: get-vw(5px);

    padding: get-vw(5px) get-vw(10px);

    .cta{
      font-size : 0;

      &.edit{
        background-image : url("../images/mobile/edit.svg");
        background-position: center;
        background-size : 100% auto;
        background-repeat: no-repeat;

        width: get-vw(20px);
        height: get-vw(20px);
      }

      &.delete{
        background-image : url("../images/mobile/delete_cross.svg");
        background-position: center;
        background-size : 100% auto;
        background-repeat: no-repeat;

        width: get-vw(20px);
        height: get-vw(20px);
      }
    }
  }
}

#setting_wrapper {
  max-width: 600px;

  h2 {
    font-size: get-vw(20px);
    margin-top: get-vw(30px);
  }

  .notification_block {
    padding: get-vw(10px);
    margin-top: get-vw(10px);

    th {
      font-size: get-vw(12px);
      line-height: get-vw(18px);
      @extend .fw-medium;
      color: $main-color;

      padding: get-vw(5px);

      &:first-of-type {
        font-size: get-vw(16px);
        width: 80%;
      }
    }

    td {
      &:nth-of-type(2), &:nth-of-type(3) {
        text-align: center;
      }

      padding-bottom: get-vw(10px);
    }
  }

  .setting_inline_form {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: get-vw(10px);

    h4 {
      margin-bottom: 0;
      font-size: 1.3rem;
    }
  }

  .setting_legal_link_wrapper {
    li {
      margin-bottom: get-vw(10px);
      font-size: 1.1rem;
    }
  }
}

.linking_step_wrapper {
  .linking_step_item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    margin: get-vw(20px);
    position: relative;

    &.step-complete {
      color: $main-color;
    }

    &:before {
      content: '\2713';

      position: absolute;
      top: 50%;
      left: get-vw(-10px);
      transform: translateY(-50%);
    }

    .linking_step_item_status {
      font-size: get-vw(12px);
      line-height: get-vw(18px);
      @extend .fw-medium;
      //color: $grey-color;
      color: $main-color;

    }

    .linking_step_item_date {
      font-size: get-vw(14px);
      line-height: get-vw(18px);
      @extend .fw-thin;
      //color: $grey-color;
      color: $main-color;

    }
  }
}

#sponsorship_wrapper {

  .sponsorship_list {
    margin-top: get-vw(20px);

    .sponsorship_item {
      margin: get-vw(20px) 0;

      .content {
        .name {
          font-size: get-vw(18px);
          line-height: get-vw(22px);
          @extend .fw-bold;
          color: $main-color;
        }
        .email {
          font-size: get-vw(16px);
          line-height: get-vw(22px);
          @extend .fw-regular;

          margin-right: get-vw(10px);
          color: $main-color;
        }
        .phone {
          font-size: get-vw(16px);
          line-height: get-vw(22px);
          @extend .fw-regular;
          color: $main-color;
        }
        .status {
          font-size: get-vw(16px);
          line-height: get-vw(22px);
          @extend .fw-regular;
          color: $cta-color;
        }
      }
    }
  }
}
